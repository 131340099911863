import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, merge } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { I18nService, Logger } from '@app/core';
import { GoogleAnalyticsService } from '@app/shared/google-analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { LocalstorageService } from '@app/shared/localstorage.service';

declare var gtag: any;

declare let fbq: Function;

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  static isBrowser = new BehaviorSubject<boolean>(null);
  cookiesAccpeted = false;
  showPopup = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private gaService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: any,
    private localStorageService: LocalstorageService
  ) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));

    this.showPopup = localStorageService.getItem('cookiesAccepted') === null;
    this.cookiesAccpeted = localStorageService.getItem('cookiesAccepted') === 'true';

    if (this.cookiesAccpeted) {
      this.enableGoogleAnalytics();
    }
  }

  enableGoogleAnalytics(setLocalStroage: boolean = false) {
    this.showPopup = false;
    if (setLocalStroage) {
      this.localStorageService.setItem('cookiesAccepted', 'true');
    }
    this.addGAScript();

    const navEndEvents = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      });
    });
  }

  disableGoogleAnalyitcs() {
    this.showPopup = false;
    this.localStorageService.setItem('cookiesAccepted', 'false');
  }

  /** Add Google Analytics Script Dynamically */
  addGAScript() {
    // register google tag manager
    const gTagManagerScript = document.createElement('script');
    gTagManagerScript.async = true;
    gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GA_TRACKING_ID}`;
    document.head.appendChild(gTagManagerScript);

    // register google analytics
    const gaScript = document.createElement('script');
    gaScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
    `;
    document.head.appendChild(gaScript);

    // Disable automatic page view to fix duplicate page count
    gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    this.router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        fbq('track', 'PageView');
      }
    });

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }
}
