<div class="container-fluid m-0 p-0">
  <div class="project-header-container">
    <h1>შეთავაზებები</h1>
  </div>
</div>
<div class="container">
  <div class="blog">
    <div class="row">
      <div class="col-12 col-md-4" *ngFor="let offer of offers">
        <app-offer-preview
          [offerImg]="offer.imageUrl"
          [brand]="offer.title"
          [detail]="offer.subheader"
          [offerDetailDocument]="offer.documentUrl"
        ></app-offer-preview>
      </div>
    </div>
  </div>
</div>
