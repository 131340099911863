<header class="container-fluid p-0">
  <div class="overlay" [ngClass]="{ active: subMenuActive }"></div>
  <nav class="tbcl-nav">
    <div class="top-header">
      <a class="tbcl-logo" routerLink="/" translate></a>
      <div class="icons-wrapper">
        <a [routerLink]="['/contact']" class="location-icon"></a>
        <a href="tel:*2277" class="contact-image"></a>
        <i class="toggle-menu fas fa-bars" (click)="toggleMenu()"></i>
      </div>
    </div>
    <div class="bottom-header" [ngClass]="{ expanded: !menuHidden }">
      <nav class="bottom-navigation">
        <ul class="navigation-list">
          <li>
            <a class="navigation-link" routerLink="/offers" routerLinkActive="active">
              <span translate>შეთავაზებები</span>
            </a>
          </li>
          <li (mouseenter)="enter()" (mouseleave)="leave()" (click)="subMenuActive = false">
            <a class="navigation-link" routerLink="/products/financial-leasing" routerLinkActive="active">
              <span translate>პროდუქტები<i class="drop-arrow fas fa-chevron-down"></i></span>
            </a>
          </li>
          <li>
            <a class="navigation-link" routerLink="/news" routerLinkActive="active">
              <span translate>სიახლეები</span>
            </a>
          </li>
          <li>
            <a class="navigation-link" routerLink="/blog" routerLinkActive="active">
              <span translate>ბლოგი</span>
            </a>
          </li>
          <li>
            <a class="navigation-link" routerLink="/for-investors" routerLinkActive="active">
              <span translate
                >INVESTORS<sup><i class="fas fa-external-link-alt"></i></sup
              ></span>
            </a>
          </li>
          <li>
            <a class="navigation-link" routerLink="/faq" routerLinkActive="active">
              <span translate>კითხვებზე პასუხი</span>
            </a>
          </li>
          <li>
            <a class="navigation-link" routerLink="/about" routerLinkActive="active">
              <span translate>ჩვენ შესახებ</span>
            </a>
          </li>
          <li>
            <a class="navigation-link" routerLink="/vacancy" routerLinkActive="active">
              <span translate>ვაკანსიები</span>
            </a>
          </li>
          <li>
            <a class="navigation-link" routerLink="/contact" routerLinkActive="active">
              <span translate>კონტაქტი</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>

    <div
      class="pop-down"
      [ngClass]="{ active: subMenuActive }"
      (mouseenter)="subMenuActive = true"
      (mouseleave)="subMenuActive = false"
    >
      <div class="wrapper">
        <h1 class="header">პროდუქტები</h1>
        <div class="list-wrapper">
          <a class="list-item" routerLink="/products/financial-leasing" (click)="subMenuActive = false">
            სტანდარტული ფინანსური ლიზინგი
          </a>
          <a class="list-item" routerLink="/products/fleet" (click)="subMenuActive = false">
            საოპერაციო ლიზინგი
          </a>
          <a class="list-item" routerLink="/products/produce-georgia" (click)="subMenuActive = false">
            აწარმოე საქართველოში
          </a>
          <a class="list-item" routerLink="/products/agriculture" (click)="subMenuActive = false">
            სოფლის განვითარების სააგენტო (RDA)
          </a>
          <a class="list-item" routerLink="/products/quick-construction" (click)="subMenuActive = false">
            სწრაფი პროდუქტი - მშენებლობა
          </a>
          <a class="list-item" routerLink="/products/auto" (click)="subMenuActive = false">
            ავტოლიზინგი
          </a>
          <a class="list-item" routerLink="/products/commercial-estate" (click)="subMenuActive = false">
            კომერციული უძრავი ქონების ლიზინგი
          </a>
          <a class="list-item" routerLink="/products/motorcycle" (click)="subMenuActive = false">
            მოტოტექნიკის ლიზინგი
          </a>
          <a class="list-item" routerLink="/products/ukuleasing" (click)="subMenuActive = false">
            უკულიზინგი
          </a>
          <a class="list-item" routerLink="/products/trade-in" (click)="subMenuActive = false">
            TRADE IN ლიზინგი
          </a>
          <a class="list-item" routerLink="/products/taxi" (click)="subMenuActive = false">
            ტაქსების დაფინანსება
          </a>
        </div>
      </div>
    </div>
  </nav>
</header>
