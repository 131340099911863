<div class="container-fluid p-0 m-0">
  <div class="footer-hr"></div>
</div>

<div class="container-fluid footer">
  <div class="row footer-height">
    <div class="col-6  col-sm-2 col-md-2 col-xl-1 my-auto text-center text-grey-us text-lg-right order-sm-1">
      <a routerLink="/about/privacy">კონფიდენციალურობა</a>
    </div>
    <div class="col-6  col-sm-2 col-md-2 col-xl-2 my-auto text-center text-grey-us text-lg-right order-sm-2">
      <a href="tel:(0 32) 2 12 27 77"><i class="fas fa-phone color-gray"></i> 995 032 212 27 77</a>
    </div>
    <div class="col-6 col-sm-2 col-md-2 col-xl-2 my-auto text-center text-grey-us text-lg-center order-sm-3">
      <a href="mailto:info@tbcleasing.ge"><i class="fas fa-envelope color-gray"></i>info@tbcleasing.ge</a>
    </div>
    <div class="col-6 col-sm-3 col-md-4 col-xl-6 my-auto text-center text-sm-left order-sm-0">
      <div class="footer-logo"></div>
    </div>
    <div class="col-6 col-sm-3 col-md-2 col-xl-1 my-auto text-center text-lg-center order-sm-4">
      <div class="blue-box">
        <a href="https://www.facebook.com/TBCLeasing/"><i class="fab fa-facebook-f fa-color-blue fa-size"></i></a>
      </div>
    </div>
  </div>
</div>
<div
  class="fb-customerchat"
  attribution="setup_tool"
  page_id="592438160872029"
  theme_color="#0f437f"
  logged_in_greeting="გამარჯობა, რით შეგვიძლია დაგეხმაროთ?"
  logged_out_greeting="გამარჯობა, რით შეგვიძლია დაგეხმაროთ?"
></div>
